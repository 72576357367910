<template>
  <div>
    <content-template
      :menuName="`${$t('expertMatching.newFiles')} ${expertmatching.expert_name} - ${expertmatching.title}`"
      :backButton="true"
      @backToPage="moveToOther('Expert Matching Upload Files')"
    >
      <template #form>
        <form>
          <Input
            v-model="edited_item.title"
             :label="$t('general.title')"
            class="s-mb-20"
            :error="validation.title"
          />
          <Textarea
            v-model="edited_item.description"
            :label="$t('general.description')"
            class="s-mb-20"
            :error="validation.description"
          />
          <p class="s-text-primary s-weight-600">{{$t('general.uploadFile')}}</p>
          <div class="s-pb-30 s-position-relative">
            <div v-if="!fileDoc.name" class="s-file-upload s-text-center">
              <div>
                <img
                  :src="require('@/assets/icons/primary/cloud_upload.png')"
                />
                <p>{{$t('general.dragAndDrop')}}</p>
                <p>{{$t('general.or')}}</p>
                <Button>{{$t('general.uploadFile')}}</Button>
              </div>
            </div>

            <input
              v-if="!fileDoc.name"
              id="document"
              ref="document"
              class="s-hide"
              type="file"
              @change="importData('document')"
              multiple
            />
            <div class="s-position-relative s-fullWidth s-center-flex">
              <div
                class="
                  s-center-flex
                  s-mb-16
                  s-bg-green-40
                  s-plr-10
                  s-radius-10
                  s-fullWidth
                "
                v-if="fileDoc.name"
              >
                <p class="s-text-primary">{{ fileDoc.name }}</p>
                <img
                  :src="require('@/assets/icons/close-primary.svg')"
                  class="s-ml-auto"
                  @click="fileDoc = {}"
                  style="cursor: pointer"
                />
              </div>
            </div>
          </div>
        </form>

        <div class="s-mt-20 s-flex">
          <div class="s-ml-auto">
            <Button color="error" @click="cancelForm()"> {{$t('general.cancel')}} </Button>
            <Button
              class="s-ml-20"
              @click.prevent="onFinish"
              :isLoading="isLoading"
              >{{$t('general.submit')}}</Button
            >
          </div>
        </div>
      </template>
    </content-template>
  </div>
</template>

<script>
import ContentTemplate from "./contentTemplate.vue";
import form from "@/mixins/form";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { ContentTemplate },
  props: ["inputType", "item"],
  mixins: [form],
  data() {
    return {
      edited_item: {
        title: "",
        description: "",
      },

      validation: {
        title: "",
        description: "",
      },
      fileDoc: {},
      isLoading: false,
    };
  },
  computed: {
    isMobile() {
      return this.$store.getters["getIsMobile"];
    },
    ...mapGetters({
      expertmatching: "expertmatching/getExpertMatching",
    }),
  },
  methods: {
    ...mapActions({
      postExpertMatching: "expertmatchingfiles/postExpertMatchingData",
      setEM: "expertmatching/setExpertMatchingSingleData",
    }),
    editItem() {
      this.edited_item = Object.assign({}, this.item);
    },
    importData(id) {
      const fileInput = this.$refs[id];
      const fileUpload = fileInput.files[0];
      const fsize = fileUpload.size;
      const file = Math.round(fsize / 1024);

      if (file <= 5000) this.fileDoc = fileUpload;
    },
    attachImage(id) {
      document.getElementById(id).click();
    },
    async onFinish() {
      this.checkEmptyField(
        this.edited_item,
        this.validation,
        true,
        this.fileDoc.name,
        this.inputType
      );
      if (!this.empty_field) {
        this.isLoading = true;
        let formData = new FormData();
        formData.append("id", this.$route.params.id);
        formData.append("title", this.edited_item.title);
        formData.append("description", this.edited_item.description);
        formData.append("file", this.fileDoc);

        await this.postExpertMatching(formData);
        if (this.$store.getters["expertmatchingfiles/getStatus"] == 1) {
          this.$router.push(
            this.$translate({
              name: "Expert Matching Upload Files",
              params: { id: this.$route.params.id },
            })
          );
        } else {
          this.showSnackbar({
            type: "error",
            text: `${$t('general.addEditFailed')}`,
          });
        }
      }
      this.isLoading = false;
    },
    cancelForm() {
      this.$router.push(
        this.$translate({
          name: "Expert Matching Upload Files",
        })
      );
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>